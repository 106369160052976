import { visualization } from "@fscrypto/domain";
import * as Events from "@fscrypto/domain/events";
import { Client } from "@fscrypto/http";
import {
  Entity,
  EntityFactory,
  EventBus,
  OptionalStore,
  PersistentStore,
  Store,
  createOptionalStore,
  createPersistentStore,
  createStore,
} from "@fscrypto/state-management";
import { ProfilePublic } from "node_modules/@fscrypto/domain/src/profile/profile";
import { filter, map } from "rxjs";
import { VisualizationClient } from "~/features/studio-2/visualization/data/visualization-client";
import * as eventBus from "~/state/events";

export class Visualization implements Entity<visualization.v3.Visualization> {
  public readonly id: string;
  store: PersistentStore<visualization.v3.Visualization>;
  ownerStore: OptionalStore<ProfilePublic>;
  constructor(
    private eventBus: EventBus<Events.VisualizationEvent>,
    private client: VisualizationClient,
    initialValue: visualization.v3.Visualization,
  ) {
    this.id = initialValue.id;

    this.store = createPersistentStore(
      initialValue,
      async (v) => {
        const res = await this.#updatePersist(v);
        return res;
      },
      500,
    );
    this.ownerStore = createOptionalStore();
    this.store.start();

    this.eventBus.events$.subscribe((e) => {
      switch (e.type) {
        case "VISUALIZATION.UPDATED.REALTIME":
          this.get();
          break;
      }
    });
  }

  async update(value: visualization.v3.Visualization) {
    this.store.set({ ...this.store.get()!, ...value }); // Optimistic update
    this.eventBus.debounceSend(Events.visualizations.updated(this.store.get()), 10);
  }

  async updateTitle(title: string) {
    this.store.set({
      ...this.store.get()!,
      config: { ...this.store.get()!.config, options: { ...this.store.get()!.config.options, title: { text: title } } },
    });
    this.eventBus.debounceSend(Events.visualizations.updated(this.store.get()), 10);
  }

  async delete() {
    await this.client.delete(this.id);
    this.eventBus.send(Events.visualizations.deleted({ id: this.id }));
  }

  async get() {
    const res = await this.client.find(this.id);
    if (!res) {
      throw new Error("Visualization not found");
    }
    this.store.set(res, false);
  }

  async getOwner() {
    const vis = this.store.get();
    const res = await this.client.findVisOwnerProfile(vis.profileId);
    this.ownerStore.set(res);
  }

  async #updatePersist(value: visualization.v3.Visualization) {
    try {
      const res = await this.client.update(this.id, value);
      this.eventBus.send(Events.visualizations.updatedPersistSuccess(res));
      return res;
    } catch (e) {
      if (e instanceof Response) {
        console.error("Error updating visualization", e);
        this.eventBus.send(
          Events.visualizations.updatedPersistFailure({ error: e.statusText, statusCode: e.status, id: this.id }),
        );
      }
      throw e;
    }
  }
}

export class VisualizationFactory implements EntityFactory<Visualization> {
  visualizations: Store<Record<string, Visualization>> = createStore({});
  // latestIds: AsyncStore<string[]>;

  constructor(
    private client: VisualizationClient,
    private eventBus: EventBus<Events.VisualizationEvent>,
  ) {
    this.eventBus.events$.subscribe((e) => {
      switch (e.type) {
        case "VISUALIZATION.DELETED":
          const v = this.visualizations.get();
          delete v[e.payload.id];
          this.visualizations.set(v);
          break;
      }
    });
  }

  async create(payload: visualization.v3.VisualizationNew) {
    const newViz = await this.client.create(payload);
    const viz = new Visualization(this.eventBus, this.client, newViz);
    this.visualizations.set({ ...this.visualizations.get(), [newViz.id]: viz });
    this.eventBus.send(Events.visualizations.created(newViz));
    return viz;
  }

  async createFromType(type: visualization.v3.VisualizationType, queryId: string) {
    const config = visualization.v3.createFromType(type);
    const newViz = await this.client.create({
      config,
      queryId,
      forkedFromId: null,
      version: "3",
    });
    const viz = new Visualization(this.eventBus, this.client, newViz);
    this.visualizations.set({ ...this.visualizations.get(), [newViz.id]: viz });
    this.eventBus.send(Events.visualizations.created(newViz));
    return viz;
  }

  async clone(id: string) {
    let viz = this.visualizations.get()[id];
    if (!viz) {
      viz = await this.getById(id);
    }
    const toClone = viz.store.get();
    return this.create({
      ...toClone,
      config: {
        ...toClone.config,
        options: {
          ...toClone.config.options,
          title: { text: (toClone.config.options.title?.text ?? "Untitled") + " (Copy)" },
        },
      },
    });
  }

  from(viz: visualization.v3.Visualization) {
    const v = new Visualization(this.eventBus, this.client, viz);
    this.visualizations.set({ ...this.visualizations.get(), [viz.id]: v });
    return v;
  }

  async getById(id: string) {
    if (this.visualizations.get()[id]) {
      return this.visualizations.get()[id];
    }
    const viz = await this.client.find(id);
    if (!viz) {
      throw new Error("Visualization not found");
    }
    const v = new Visualization(this.eventBus, this.client, viz);
    this.visualizations.set({ ...this.visualizations.get(), [viz.id]: v });
    return v;
  }

  from$(id: string) {
    return this.visualizations.value$.pipe(
      map((v) => v[id]),
      filter(Boolean),
    );
  }

  fromQueryId$(queryId: string) {
    return this.visualizations.value$.pipe(
      map((v) => Object.values(v).filter((viz) => viz.store.get().queryId === queryId)),
    );
  }
  async findLatest() {
    const visualizations = await this.client.findLatest(8);

    visualizations.forEach((v) => {
      const viz = new Visualization(this.eventBus, this.client, v);
      this.visualizations.set({ ...this.visualizations.get(), [v.id]: viz });
    });
    return visualizations.map((v) => v.id);
  }
}

export const visualizationFactory = new VisualizationFactory(
  new VisualizationClient(new Client()),
  eventBus.v2.eventBus as EventBus<Events.VisualizationEvent>,
);
