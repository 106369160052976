import { generateRandomName } from "../../utils/random-names";
import { Visualization, VisualizationType } from "./schema";

export function createFromType(type: VisualizationType): Visualization["config"] {
  switch (type) {
    case "bar":
      return createBar();
    case "bar-normalized":
      return createBarNormalized();
    case "bar-stacked":
      return createBarStacked();
    case "bar-horizontal":
      return createBarHorizontal();
    case "bar-line":
      return createBarLine();
    case "bar-horizontal-normalized":
      return createBarHorizontalNormalized();
    case "bar-horizontal-stacked":
      return createBarHorizontalStacked();
    case "line":
      return createLine();
    case "area":
      return createArea();
    case "area-normalized":
      return createAreaNormalized();
    case "area-stacked":
      return createAreaStacked();
    case "pie":
      return createPie();
    case "scatter":
      return createScatter();
    case "viz-table":
      return createTable();
    case "big-number":
      return createBigNumber();
    case "histogram":
      return createHistogram();
    case "candlestick":
      return createCandlestick();
    default:
      throw new Error("Unknown chart type");
  }

  function createBar(): Visualization["config"] {
    return {
      inputs: {
        type: "bar",
        config: {},
      },
      options: {
        title: {
          text: generateRandomName(),
        },
        plotOptions: {
          bar: {},
        },
      },
    };
  }

  function createBarNormalized(): Visualization["config"] {
    return {
      inputs: {
        type: "bar-normalized",
        config: {},
      },
      options: {
        title: {
          text: generateRandomName(),
        },
        plotOptions: {
          column: {
            stacking: "percent",
          },
        },
      },
    };
  }

  function createBarStacked(): Visualization["config"] {
    return {
      inputs: {
        type: "bar-stacked",
        config: {},
      },
      options: {
        title: {
          text: generateRandomName(),
        },
        plotOptions: {
          column: {
            stacking: "normal",
          },
        },
      },
    };
  }

  function createBarHorizontal(): Visualization["config"] {
    return {
      inputs: {
        type: "bar-horizontal",
        config: {},
      },
      options: {
        title: {
          text: generateRandomName(),
        },
        plotOptions: {
          bar: {},
        },
      },
    };
  }

  function createBarHorizontalNormalized(): Visualization["config"] {
    return {
      inputs: {
        type: "bar-horizontal-normalized",
        config: {},
      },
      options: {
        title: {
          text: generateRandomName(),
        },
        plotOptions: {
          bar: {
            stacking: "percent",
          },
        },
      },
    };
  }

  function createBarHorizontalStacked(): Visualization["config"] {
    return {
      inputs: {
        type: "bar-horizontal-stacked",
        config: {},
      },
      options: {
        title: {
          text: generateRandomName(),
        },
        plotOptions: {
          bar: {
            stacking: "normal",
          },
        },
      },
    };
  }

  function createLine(): Visualization["config"] {
    return {
      inputs: {
        type: "line",
        config: {},
      },
      options: {
        title: {
          text: generateRandomName(),
        },
        plotOptions: {},
      },
    };
  }
}

function createArea(): Visualization["config"] {
  return {
    inputs: {
      type: "area",
      config: {},
    },
    options: {
      title: {
        text: generateRandomName(),
      },
      plotOptions: {
        area: {},
      },
    },
  };
}

function createAreaNormalized(): Visualization["config"] {
  return {
    inputs: {
      type: "area-normalized",
      config: {},
    },
    options: {
      title: {
        text: generateRandomName(),
      },
      plotOptions: {
        area: {
          stacking: "percent",
        },
      },
    },
  };
}

function createAreaStacked(): Visualization["config"] {
  return {
    inputs: {
      type: "area-stacked",
      config: {},
    },
    options: {
      title: {
        text: generateRandomName(),
      },
      plotOptions: {
        area: {
          stacking: "normal",
        },
      },
    },
  };
}

function createPie(): Visualization["config"] {
  return {
    inputs: {
      type: "pie",
      config: {},
    },
    options: {
      title: {
        text: generateRandomName(),
      },
    },
  };
}

function createScatter(): Visualization["config"] {
  return {
    inputs: {
      type: "scatter",
      config: {},
    },
    options: {
      title: {
        text: generateRandomName(),
      },
    },
  };
}

function createTable(): Visualization["config"] {
  return {
    inputs: {
      type: "viz-table",
      config: {
        columnOptions: {},
      },
    },
    options: {
      title: {
        text: generateRandomName(),
      },
    },
  };
}
function createBarLine(): Visualization["config"] {
  return {
    inputs: {
      type: "bar-line",
      config: {},
    },
    options: {
      title: {
        text: generateRandomName(),
      },
    },
  };
}

function createBigNumber(): Visualization["config"] {
  return {
    inputs: {
      type: "big-number",
      config: {
        autoFormat: true,
      },
    },
    options: {
      title: {
        text: generateRandomName(),
      },
    },
  };
}

function createHistogram(): Visualization["config"] {
  return {
    inputs: {
      type: "histogram",
      config: {},
    },
    options: {
      title: {
        text: generateRandomName(),
      },
    },
  };
}

function createCandlestick(): Visualization["config"] {
  return {
    inputs: {
      type: "candlestick",
      config: {
        colors: {
          upwardTrend: "#008000",
          downwardTrend: "#ff0000",
        },
      },
    },
    options: {
      title: {
        text: generateRandomName(),
      },
      opposite: true,
    },
  };
}
