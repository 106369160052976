import { v4 as uuid } from "uuid";
import { uniqBy } from "lodash-es";
import { QueryParameter } from "./query";
import { Liquid } from "liquidjs";

export function newQueryParameter(name: string, queryId: string): QueryParameter {
  return {
    id: uuid(),
    name,
    queryId,
    type: "text",
    restrictedValues: null,
    value: "",
    createdById: null,
    updatedById: null,
  };
}

type ParamToken = { begin: number; name: string; end: number };

export function getParamsFromStatement(statement: string): ParamToken[] {
  const engine = new Liquid();
  // First get the parameters from the statement
  const paramsFromStatement = engine
    .parse(statement)
    .filter((param) => "value" in param)
    .map(({ token }) => {
      const name = (token as unknown as { content: string }).content;
      const { begin, end } = token;
      return { begin, name, end };
    });
  return uniqBy(paramsFromStatement, (p) => p.name);
}

export function getUpdatedQueryParams(
  existingParams: QueryParameter[],
  statement: string,
  queryId: string,
): QueryParameter[] {
  let newParams: ParamToken[];
  try {
    newParams = getParamsFromStatement(statement);
  } catch {
    return existingParams;
  }

  // Check if there are difference (i.e. rename)
  if (newParams.length === existingParams.length) {
    const names = new Set([...newParams.map((p) => p.name), ...existingParams.map((p) => p.name)]);
    if (names.size === existingParams.length) {
      // No changes
      return existingParams;
    }
    const renamed = newParams.find((p) => !existingParams.find((ep) => ep.name === p.name));
    const paramToRename = existingParams.find((p) => !newParams.find((ep) => ep.name === p.name));
    if (renamed && paramToRename) {
      const updated = { ...paramToRename, name: renamed.name };
      return existingParams.map((p) => (p.name === paramToRename.name ? updated : p));
    }
  }
  // New params added
  if (newParams.length > existingParams.length) {
    const addedParams = newParams.filter((p) => !existingParams.find((ep) => ep.name === p.name));
    if (addedParams.length) {
      const paramsToAdd = addedParams.map((p) => newQueryParameter(p.name, queryId));
      return [...existingParams, ...paramsToAdd];
    }
    return existingParams;
  }
  // Param removed
  const filtered = existingParams.filter((p) => newParams.find((ep) => ep.name === p.name));
  return filtered;
}
