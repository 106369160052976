import { visualization } from "@fscrypto/domain";
import { Profile } from "@fscrypto/domain/profile";
import { Client, HttpClient } from "@fscrypto/http";
import { $path } from "remix-routes";

export class VisualizationClient {
  constructor(private client: HttpClient) {}

  async create(payload: visualization.v3.VisualizationNew): Promise<visualization.v3.Visualization> {
    return this.client.post(
      $path("/api/queries/:id/visualizations/v3/create", { id: payload.queryId }),
      payload,
      visualization.v3.schema,
    );
  }

  async update(id: string, payload: visualization.v3.Visualization): Promise<visualization.v3.Visualization> {
    return this.client.post($path("/api/visualizations/v3/:id/update", { id }), payload, visualization.v3.schema);
  }

  async delete(id: string): Promise<void> {
    return this.client.delete($path("/api/visualizations/v3/:id/delete", { id }));
  }

  async find(id: string): Promise<visualization.v3.Visualization | undefined> {
    return this.client.get($path("/api/visualizations/v3/:id", { id }), visualization.v3.schema);
  }

  /**
   * Find a v2 or v3 visualization by id
   */
  async findAnyVersion(id: string): Promise<visualization.Visualization | visualization.v3.Visualization | undefined> {
    const res = await this.client.get<{ version: "1" | "2" | "3" }>($path("/api/visualizations/:id", { id }));
    if (!res) return;
    if (res.version === "3") {
      return visualization.v3.schema.parse(res);
    }
    if (res.version === "2") {
      return visualization.schema.parse(res);
    }
    console.warn("Unsupported visualization version", res.version);
    return;
  }

  async findAll(): Promise<visualization.v3.Visualization[]> {
    const visualizations = await this.client.get<visualization.v3.Visualization[]>($path("/api/visualizations/v3"));
    return visualizations.map((v) => visualization.v3.schema.parse(v));
  }
  async findLatest(number: number): Promise<visualization.v3.Visualization[]> {
    const visualizations = await this.client.get<visualization.v3.Visualization[]>(
      $path("/api/visualizations/v3/latest/:number", { number }),
    );
    return visualizations.map((v) => visualization.v3.schema.parse(v));
  }

  async findVisOwnerProfile(id: string): Promise<Profile> {
    return this.client.get($path("/api/profiles/:id/public", { id }));
  }
}

export const visualizationClient = new VisualizationClient(new Client());
