import { visualization } from "@fscrypto/domain";
import * as Events from "@fscrypto/domain/events";
import { VisualizationDeleted } from "@fscrypto/domain/events";
import { Client, type HttpClient } from "@fscrypto/http";
import {
  type Entity,
  EntityFactory,
  EventBus,
  type PersistentStore,
  Store,
  createPersistentStore,
  createStore,
} from "@fscrypto/state-management";
import { $path } from "remix-routes";
import { filter, map } from "rxjs";
import { v2 as eventBus } from "~/state/events";

export class V2VisualizationEntity implements Entity<visualization.Visualization> {
  public readonly id: string;
  store: PersistentStore<visualization.Visualization>;
  constructor(
    private client: V2VisualizationClient,
    private eventBus: EventBus<VisualizationDeleted>,
    vis: visualization.Visualization,
  ) {
    this.id = vis.id;
    this.store = createPersistentStore(
      vis,
      async (v) => {
        return this.client.update(v);
      },
      500,
    );
    this.store.start();
  }

  update(vis: visualization.VisualizationUpdate) {
    this.store.set({ ...this.store.get()!, ...vis });
  }

  updateTitle(title: string) {
    const vis = this.store.get();
    this.store.set({
      ...vis,
      title,
    });
  }

  async delete() {
    await this.client.delete(this.id);
    this.eventBus.send(Events.visualizations.deleted({ id: this.id }));
  }
}

export class V2VisualizationFactory implements EntityFactory<V2VisualizationEntity> {
  visualizations: Store<Record<string, V2VisualizationEntity>> = createStore({});
  constructor(
    private client: V2VisualizationClient,
    private eventBus: EventBus<VisualizationDeleted>,
  ) {
    this.eventBus.events$.subscribe((e) => {
      switch (e.type) {
        case "VISUALIZATION.DELETED":
          const v = this.visualizations.get();
          delete v[e.payload.id];
          this.visualizations.set(v);
          break;
      }
    });
  }

  async create(vis: visualization.VisualizationNew) {
    const newVis = await this.client.create(vis);
    const visEntity = new V2VisualizationEntity(this.client, this.eventBus, newVis);
    this.visualizations.set({ ...this.visualizations.get(), [newVis.id]: visEntity });
    return visEntity;
  }

  async getById(id: string) {
    if (this.visualizations.get()[id]) {
      return this.visualizations.get()[id];
    }
    const viz = await this.client.getById(id);
    if (!viz) {
      throw new Error("Visualization not found");
    }
    const v = new V2VisualizationEntity(this.client, this.eventBus, viz);
    this.visualizations.set({ ...this.visualizations.get(), [viz.id]: v });
    return v;
  }

  async clone(id: string) {
    let viz = this.visualizations.get()[id];
    if (!viz) {
      viz = await this.getById(id);
    }
    const toClone = viz.store.get();
    return this.create({
      ...toClone,
      title: toClone.title + " (Copy)",
    });
  }

  from(vis: visualization.Visualization) {
    const entity = new V2VisualizationEntity(this.client, this.eventBus, vis);
    this.visualizations.set({ ...this.visualizations.get(), [vis.id]: entity });
    return entity;
  }

  from$(id: string) {
    return this.visualizations.value$.pipe(
      map((v) => v[id]),
      filter(Boolean),
    );
  }

  fromQueryId$(queryId: string) {
    return this.visualizations.value$.pipe(
      map((v) => Object.values(v).filter((viz) => viz.store.get().queryId === queryId)),
    );
  }
}
class V2VisualizationClient {
  constructor(private client: HttpClient) {}

  async update(vis: visualization.VisualizationUpdate) {
    return await this.client.post($path("/api/visualizations/:id/update", { id: vis.id! }), vis, visualization.schema);
  }

  async delete(id: string) {
    return await this.client.post($path("/api/visualizations/:id/delete", { id }), {});
  }

  async getById(id: string) {
    return await this.client.get($path("/api/visualizations/:id", { id }), visualization.schema);
  }

  async create(vis: visualization.VisualizationNew) {
    return await this.client.post(
      $path("/api/queries/:id/visualizations/create", { id: vis.queryId }),
      vis,
      visualization.schema,
    );
  }
}

export const v2VisualizationFactory = new V2VisualizationFactory(
  new V2VisualizationClient(new Client()),
  eventBus.eventBus as EventBus<VisualizationDeleted>,
);
