import { visualization } from "..";

export type VisualizationCreated = {
  type: "VISUALIZATION.CREATED";
  version: 2;
  payload: visualization.v3.Visualization;
};

export type VisualizationUpdated = {
  type: "VISUALIZATION.UPDATED";
  version: 2;
  payload: visualization.v3.Visualization;
};

export type VisualizationUpdatedRealtime = {
  type: "VISUALIZATION.UPDATED.REALTIME";
  payload: {
    timestamp: number;
  };
};

export type VisualizationUpdatedPersistSuccess = {
  type: "VISUALIZATION.UPDATED.PERSIST.SUCCESS";
  payload: visualization.v3.Visualization;
};

export type VisualizationUpdatedPersistFailure = {
  type: "VISUALIZATION.UPDATED.PERSIST.FAILURE";
  payload: {
    error: string;
    statusCode: number;
    id: string;
  };
};

export type VisualizationDeleted = {
  version: 2;
  type: "VISUALIZATION.DELETED";
  payload: {
    id: string;
  };
};

const created = (payload: visualization.v3.Visualization) => {
  return {
    type: "VISUALIZATION.CREATED",
    version: 2,
    payload,
  } satisfies VisualizationCreated;
};

const updated = (payload: visualization.v3.Visualization) => {
  return {
    type: "VISUALIZATION.UPDATED",
    version: 2,
    payload,
  } satisfies VisualizationUpdated;
};

const updatedRealtime = (payload: { timestamp: number }) => {
  return {
    type: "VISUALIZATION.UPDATED.REALTIME",
    payload,
  } satisfies VisualizationUpdatedRealtime;
};

const updatedPersistSuccess = (payload: visualization.v3.Visualization) => {
  return {
    type: "VISUALIZATION.UPDATED.PERSIST.SUCCESS",
    payload,
  } satisfies VisualizationUpdatedPersistSuccess;
};

const updatedPersistFailure = (payload: { error: string; statusCode: number; id: string }) => {
  return {
    type: "VISUALIZATION.UPDATED.PERSIST.FAILURE",
    payload,
  } satisfies VisualizationUpdatedPersistFailure;
};

const deleted = (payload: { id: string }) => {
  return {
    type: "VISUALIZATION.DELETED",
    version: 2,
    payload,
  } satisfies VisualizationDeleted;
};

export const visualizations = {
  created,
  updated,
  updatedRealtime,
  updatedPersistSuccess,
  updatedPersistFailure,
  deleted,
};

export type VisualizationEvent =
  | VisualizationCreated
  | VisualizationUpdated
  | VisualizationUpdatedRealtime
  | VisualizationUpdatedPersistSuccess
  | VisualizationUpdatedPersistFailure
  | VisualizationDeleted;
