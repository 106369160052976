import { query } from "..";

export type QueryCreated = {
  type: "QUERY.CREATED";
  version: 2;
  payload: query.Query;
};

export type QueryUpdated = {
  type: "QUERY.UPDATED";
  version: 2;
  payload: query.Query;
};

export type QueryUpdatedRealtime = {
  type: "QUERY.UPDATED.REALTIME";
  payload: {
    timestamp: number;
  };
};

export type QueryUpdatedPersistSuccess = {
  type: "QUERY.UPDATED.PERSIST.SUCCESS";
  payload: query.Query;
};

export type QueryUpdatedPersistFailure = {
  type: "QUERY.UPDATED.PERSIST.FAILURE";
  payload: {
    error: string;
    statusCode: number;
    id: string;
  };
};

export type QueryDeleted = {
  version: 2;
  type: "QUERY.DELETED";
  payload: {
    id: string;
  };
};

export type QueryDeletedFailure = {
  type: "QUERY.DELETED.FAILURE";
  payload: {
    id: string;
  };
};

const created = (payload: query.Query) => {
  return {
    type: "QUERY.CREATED",
    version: 2,
    payload,
  } satisfies QueryCreated;
};

const updated = (payload: query.Query) => {
  return {
    type: "QUERY.UPDATED",
    version: 2,
    payload,
  } satisfies QueryUpdated;
};

const updatedRealtime = (payload: { timestamp: number }) => {
  return {
    type: "QUERY.UPDATED.REALTIME",
    payload,
  } satisfies QueryUpdatedRealtime;
};

const updatedPersistSuccess = (payload: query.Query) => {
  return {
    type: "QUERY.UPDATED.PERSIST.SUCCESS",
    payload,
  } satisfies QueryUpdatedPersistSuccess;
};

const updatedPersistFailure = (payload: { error: string; statusCode: number; id: string }) => {
  return {
    type: "QUERY.UPDATED.PERSIST.FAILURE",
    payload,
  } satisfies QueryUpdatedPersistFailure;
};

const deleted = (payload: { id: string }) => {
  return {
    type: "QUERY.DELETED",
    version: 2,
    payload,
  } satisfies QueryDeleted;
};

const deletedFailure = (payload: { id: string }) => {
  return {
    type: "QUERY.DELETED.FAILURE",
    payload,
  } satisfies QueryDeletedFailure;
};

export const queries = {
  created,
  updated,
  updatedRealtime,
  updatedPersistSuccess,
  updatedPersistFailure,
  deleted,
  deletedFailure,
};

export type QueryEvent =
  | QueryCreated
  | QueryUpdated
  | QueryDeleted
  | QueryUpdatedPersistSuccess
  | QueryUpdatedPersistFailure
  | QueryUpdatedRealtime
  | QueryDeletedFailure;
