import { Query, QueryFilter, QueryNew, QueryUpdate, schema } from "@fscrypto/domain/query";
import { HttpClient, Client } from "@fscrypto/http";
import { ExecutionType, QueryRunParams, QueryRunResult } from "@fscrypto/domain/query-run";
import { $path } from "remix-routes";

export class QueryClient {
  constructor(private readonly client: HttpClient) {
    this.client = client;
  }
  async create(_payload: QueryNew): Promise<Query> {
    return this.client.post($path("/api/queries", {}), _payload, schema);
  }

  async get(id: string): Promise<Query> {
    try {
      return await this.client.get($path("/api/queries/:id", { id }), schema);
    } catch (e) {
      console.log(e);
      throw new Error("Error Fetching Query");
    }
  }

  async getQueriesWithFilter(filter: QueryFilter): Promise<Query[]> {
    try {
      const data = await this.client.get<Query[]>(
        $path("/api/queries/filtered", {
          profileId: filter.profileId,
          hasJsonEndpointAccess: filter.hasJsonEndpointAccess ?? false,
        }),
      );
      return data;
    } catch (e) {
      throw new Error("Error Fetching Filtered Queries");
    }
  }

  async update(id: string, payload: QueryUpdate) {
    const result = await this.client.post($path("/api/queries/:id/update", { id }), payload, schema);
    return result;
  }

  async fork(id: string): Promise<Query> {
    const result = await this.client.post($path("/api/queries/:id/fork", { id }), {}, schema);
    return result;
  }

  async fetchTablePreview(table: string): Promise<{ table: string; data: QueryRunResult }> {
    try {
      const result = await this.client.get<QueryRunResult>(`/api/schemas/preview/${table}`);
      return { table: table, data: result };
    } catch (e) {
      throw new Error(`Error Fetching Table Preview: ${e}`);
    }
  }
  async fetchStatus(queryRunId: string): Promise<QueryRunResult> {
    try {
      const result = await this.client.get<QueryRunResult>(`/api/query-runs/${queryRunId}/status`);
      return result;
    } catch (e) {
      throw new Error(`Error Fetching Query Run Status: ${e}`);
    }
  }
  async fetchResults(queryRunId: string): Promise<QueryRunResult> {
    try {
      return this.client.get<QueryRunResult>(`/api/query-runs/${queryRunId}/results`);
    } catch (e) {
      throw new Error(`Error Fetching Query Run Data: ${e}`);
    }
  }

  async cancel(queryRunId: string): Promise<QueryRunResult> {
    try {
      const data = await this.client.post<{ result: QueryRunResult }>(`/api/query-runs/${queryRunId}/cancel`, {});
      return data.result;
    } catch (e) {
      throw new Error("Error Cancelling Query Run");
    }
  }

  async execute({
    queryId,
    executionType,
    statement,
    parameters,
  }: {
    queryId: string;
    executionType: ExecutionType;
    statement: string;
    parameters?: QueryRunParams;
  }): Promise<QueryRunResult> {
    try {
      const data = await this.client.post<{ result: QueryRunResult }>(`/api/queries/${queryId}/execute/v2`, {
        statement,
        executionType,
        parameters,
      });
      return data.result;
    } catch (e) {
      let message = "An error has occurred.";
      if (e instanceof Error) message = e.message;
      throw new Error(message);
    }
  }

  async delete(id: string) {
    const query = await this.client.post(`/api/queries/${id}/delete`, {});
    return query;
  }
}
export const queryClient = new QueryClient(new Client());
